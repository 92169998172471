@import '../../../../static/styles/variables';
@import '../../../../static/styles/mediaqueries';
@import '../../../../static/styles/buttons';
@import "~react-id-swiper/lib/styles/scss/swiper.scss";


.header {
  height: 62vh;
  min-height: 625px;
  width: 100%;
  background-color: $primary-bg;
  position: relative;

  @include media(m-device) {
    height: auto;
    gap: 0;
  }

  &_container {
    width: auto;
    display: flex;
    justify-content: center;
    gap: 5rem;

    &__title {
      margin: auto 0;
      display: flex;
      flex-direction: column;
    }

    @include media(m-device) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10vh;
      gap: 0;
    }

    .title {
      font-family: $baderCrompress;
      font-size: 72px;
      text-align: center;
      line-height: 80px;
      text-transform: uppercase;

      @include media(m-device) {
        display: block;
        line-height: 70px;
      }

      b {
        font-family: $sensa;
        color: $primary;
        text-transform: none;
        font-size: 92px;
        margin: 0;
        position: relative;
        top: -33px;

        @include media(m-device) {
          margin-top: 3vh;
          display: block;
          line-height: 50px;
          font-size: 76px;
        }
      }
    }

    &__promocode {
      padding-top: 0.7rem !important;

      span {
        font-family: $baderNarrowBold !important;
      }

      button {
        background-color: $white !important;
      }

      @include media(m-device) {
        max-width: 290px;
        margin: auto;
        text-align: center;
        justify-content: center;
      }
    }
  }


  .advantages {
    width: 90%;
    max-width: 1400px;
    border-radius: 4px;
    box-shadow: 4px 6px 40px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;

    display: flex;
    justify-content: space-around;
    height: 120px;
    margin: auto;
    position: relative;
    overflow: hidden;

    &_container {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -40px;
    }

    @include media(m-device) {
      width: 100%;
      height: 180px;
      position: relative;
      top: 2rem;

    }

    /*
    :global {
      @import "~react-id-swiper/lib/styles/scss/swiper.scss";

      .swiper-pagination-bullet-active {
        background-color: $black;
      }

      .swiper-button-prev, .swiper-button-next {
        background-size: auto;
        width: 20px;
        height: 20px;
        margin: -10px 20px 0 20px;
      }

      .swiper-button-next {
        background-image: url('/static/images/pictos/icon-arrow-right.svg');
      }

      .swiper-button-prev {
        background-image: url('/static/images/pictos/icon-arrow-left.svg');
      }
    }

     */

    @include media(l-device) {

      >div {
        >h2 {
          max-width: 73%;
          color: #2F1E00;
        }
      }

      >div:last-of-type {
        >h2 {
          max-width: 70%;

          >span {
            margin-right: 5px;
          }
        }
      }
    }


  }
}

.advantage {
  display: flex;
  align-items: center;
  width: 33%;
  justify-content: space-evenly;

  @include media(m-device) {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden
  }

  @include media(s-device) {
    height: 90%;
    width: fit-content;
    justify-content: center;
    align-items: center;
  }

  &>img {
    height: 64px;
    width: 64px;
    margin-right: 10px;

    @include media(m-device) {
      margin-right: 1.5rem;
    }
  }

  &>h2 {
    font-family: $baderCrompress;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1.5;
    max-width: 90%;
    color: #2F1E00;

    @include media(m-device) {
      width: 65%;
      font-size: 25px;
      line-height: 1.5;
    }

    span {
      display: block;
      font-family: $baderNarrow;
      font-size: 16px;
      color: $sheitan;
      text-transform: none;
      line-height: 1.5;

      @include media(m-device) {
        width: 80%;
      }
    }
  }
}
.btn__primary,
.btn__light {
  width: 170px;
  height: 48px;
  border: none;
}

.control {
  position: absolute;
}

.arrow {


  &_left {
    position: absolute;
    left: 0;
    z-index: 4;
  }

  &_right {
    position: absolute;
    right: 0;
    z-index: 4;
  }
}


.mobile_container {
  width: 100% !important;
}

/*
@include media(m-device) {
  &:global {
    .swiper-container {
      height: 100%;
    }

    .swiper-pagination-bullet-active {
      background-color: $black;
    }
  }
}

 */

.home_container__promocode {
  @include media(s-device) {
    margin: 10px;
    display: flex;
    flex-direction: column;

    >div {
      margin-top: 10px;
    }
  }
}

.btn {
  &__container {

    a,
    button {
      @include media(m-device) {
        display: flex;
        width: 90vw;
        margin: 5px auto;
        margin-top: 0;
      }
    }
  }
}
